import "jquery"
import "angular"
import "angular-route"
import "angular-resource"
import "angular-cookies"
import "angular-sanitize"
import "angular-animate"
import "angular-recaptcha"
import "angular-slugify"
import "bootstrap"
import "bootstrap/dist/css/bootstrap.min.css"
